<template>
	<div style="width: 100%;height: 100vh;">
		<div v-html="html" style="width: 100%;height: 80%;"></div>
		<!-- <iframe src="" frameborder="0" class="ifr"></iframe> -->
		<input type="text" :value="Url" style="width: 100%;height: 20%;">
		<!-- <div v-if="flage">
			<div>
				<img src="https://dgjmpgj.oss-cn-shanghai.aliyuncs.com/static/success.gif" alt="">
			</div>
			<div style="width: 100%;text-align: center;color: green;margin-top: 20px;font-weight: bold;">已成功关注！</div>
		</div> -->
	</div>
</template>

<script>
	// const axi = require('../assets/axi.js')
	import axios from 'axios'
	export default {
		data() {
			return {
				flage: false,
				Url: '',
				html: '<iframe  frameborder="0" src="https://open.douyin.com/platform/oauth/connect?client_key=awzdxdl9xrogzlyq&response_type=code&scope=user_info&redirect_uri=https%3A%2F%2Fwww.rhdgj.com%2F%23%2FTiktokAuthorization" style="width: 100%;height: 80%;"/>'
			}
		},
		mounted() {
			// let url = 'http://www.rhdgj.com/?code=02153K000TFmlQ1k7W000YjmTd353K0p&state=31#/transfer'
			let url = window.location.href
			console.log(url)
			this.Url = url
			if (url.split('?')[1]) {
				// let urlquery = url.split('?')[1]
				// let code = urlquery.split('code=')[1]
				// code = code.split('&state')[0]
				// let ID = urlquery.split('&state=')[1]
				// ID = ID.split('#/')[0]
				// console.log(code)
				// console.log(ID)
				// let data = {
				// 	code: code,
				// 	UID: ID
				// }
				// console.log(data)
				// axios.post('https://dzgmpgjapi.ronghengzn.com/api/Applet/IsFan', data).then(res => {
				// 	console.log(res)
				// 	if (res.data.data.Dto == 1) {
				// 		this.flage = true
				// 	} else {
				// 		window.location.href = 'https://mp.weixin.qq.com/s/XIk38uSo5rc0jHQAJNMJvg'
				// 	}
				// }).catch(function(error) {
				// 	console.log(error)
				// 	if (error.response) {
				// 		if (error.response.status == 500) {
				// 			loading.close();
				// 			Message({
				// 				message: '服务器错误',
				// 				type: 'error'
				// 			});

				// 		}
				// 	}
				// })
			}

		}
	}
</script>

<style>
</style>
